import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from './Util_format';
import { theme } from '@style/styled.js';
import { NumericFormat } from 'react-number-format';
import config from './config/env.json';
import { fetchCreateLiabilitiesOther } from './Util_API_calls';

function ModalLiabilityCreateOthers({ onClose, isOpen, liabilityType, user_institutions, handleRefreshLiabilities }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [accountOpenDate, setAccountOpenDate] = useState(new Date()); // Set default date to current date
  const [user_liability_currency, setuser_liability_currency] = useState('');
  const [user_liability_balance, setuser_liability_balance] = useState('');
  const [user_liability_label, setCreditLabel] = useState(''); // State for the bank account name
  const sortedCurrencies = [...config.SUPPORTED_CURRENCIES].sort((a, b) => a.name.localeCompare(b.name));
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const sortedCountries = [...config.SUPPORTED_COUNTRIES].sort((a, b) => a.name.localeCompare(b.name));

  const handleProcessing = async () => {
    try {
      setErrorMessage('');
      // Validation checks
      if (!user_liability_label) {
        setErrorMessage(t('please_enter_other_liability_label'));
        return false;
      }
      if (!user_liability_balance) {
        setErrorMessage(t('please_enter_opening_balance'));
        return false;
      }
      if (!user_liability_currency) {
        setErrorMessage(t('please_select_the_currency'));
        return false;
      }
      if (!selectedCountry) {
        setErrorMessage(t('please_select_the_country'));
        return false;
      }
      if (!accountOpenDate) {
        setErrorMessage(t('please_select_the_opening_date'));
        return false;
      }

      setIsProcessing(true); // Start processing
      const data = await fetchCreateLiabilitiesOther(user_liability_label, user_liability_balance, user_liability_currency, selectedCountry.name, accountOpenDate.toISOString().split('T')[0]);
      if (data) {
        setTaskCompleted(true);
        handleRefreshLiabilities();
        setSuccessMessage('COMPLETED SUCCESFULLY');
      } else {
        setErrorMessage("ERROR ON FETCHING DATA");
      }

      // onClose(); // Closes the modal after successful operation
    } catch (error) {
      console.error('Error:', error.message);
      // Here, handle the error, e.g., by setting an error state or showing a notification
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const details_area = {
    paddingLeft: '20px',
    width: '400px'
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const rowteStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' };
  const inputStyle = { width: '330px' };

  // Reset modal state when it opens
  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setSuccessMessage(null);
      setIsProcessing(false);
      setCreditLabel(''); // Reset to empty string or initial value
      setAccountOpenDate(new Date()); // Reset to current date or use null for no selection
      setuser_liability_currency(''); // Reset to empty string or initial value
      setuser_liability_balance(''); // Reset to empty string or initial value
      setTaskCompleted(false);
      setSelectedCountry(null);
    }
  }, [isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose} // Use the modified handleClose function
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_liability_title_' + liabilityType)}</DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <div className="success-icon">!</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
        {!taskCompleted && (
          <>
            <ListItem disablePadding style={rowteStyle}>
              <TextField
                label={t('other_liability_label')} // Replace with appropriate text
                variant="outlined"
                value={user_liability_label}
                onChange={(e) => setCreditLabel(e.target.value)}
                style={inputStyle}
              />
              <ListItemText primary={t('give_a_name_to_this_other_liability')} style={details_area} /> {/* You can replace 'note' with a new note specific to this field */}
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <NumericFormat
                // value and other props
                customInput={TextField}
                label={t('opening_balance')}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                
                onValueChange={(values) => {
                  const { value } = values;
                  setuser_liability_balance(value);
                }}
                style={inputStyle}
              />
              <ListItemText primary={t('balance')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <FormControl style={inputStyle}>
                <InputLabel id="currency-select-label">{t('currency')}</InputLabel>
                <Select
                  labelId="currency-select-label"
                  id="currency-select"
                  value={user_liability_currency}
                  label={t('currency')}
                  onChange={(event) => setuser_liability_currency(event.target.value)}
                >
                  {sortedCurrencies.map((currency) => (
                    <MenuItem key={currency.currency} value={currency.currency}>
                      {`${currency.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ListItemText primary={t('transactions_recorded_in_this_currency')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <FormControl style={inputStyle}>
                <Autocomplete
                  id="country-select"
                  options={sortedCountries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={selectedCountry ? '' : t('country')}
                      InputLabelProps={{ shrink: false }}
                    />
                  )}
                  value={selectedCountry}
                  onChange={(_, newValue) => setSelectedCountry(newValue)}
                  fullWidth
                  disabled={isProcessing}
                />
              </FormControl>
              <ListItemText primary={t('country_where_it_is_located')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <LocalizationProvider dateAdapter={AdapterDateFns} style={inputStyle} >
                <DatePicker
                  label={t('opening_date')}
                  value={accountOpenDate}
                  onChange={(newValue) => {
                    setAccountOpenDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))}
                />
              </LocalizationProvider>
              <ListItemText primary={t('data_tracked_from_this_day_onwards')} style={details_area} />
            </ListItem>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" disabled={isProcessing}>
            {t('close')}
          </Button>
          {!taskCompleted && (
            <Button onClick={handleProcessing} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ModalLiabilityCreateOthers;
