import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, Typography, DialogContent } from '@mui/material';
import { theme } from '@style/styled.js';
import config from './config/env.json';
import { t } from './Util_format';
import { fetchCreateInstitution } from './Util_API_calls';


function ModalInstitutionCreate({ onClose, isOpen, assetType, handleRefreshAssets}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [userInstitutionLabel, setuserInstitutionLabel] = useState('');
  const [userInstitutionCountry, setuserInstitutionCountry] = useState(null);
  const sortedCountries = [...config.SUPPORTED_COUNTRIES].sort((a, b) => a.name.localeCompare(b.name));
  const [taskCompleted, setTaskCompleted] = useState(false);

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const handleProcessing = async () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    if (userInstitutionLabel.length < 3 || userInstitutionLabel.length > 30) {
      setErrorMessage(t('institution_name_too_short'));
      return;
    }
    if (!userInstitutionCountry) {
      setErrorMessage(t('please_select_the_country'));
      return;
    }

    try {
      setIsProcessing(true); // Start processing
      // Simulation of API call to save data
      const data = await fetchCreateInstitution(userInstitutionLabel, userInstitutionCountry.name);
      if (data) {
        setTaskCompleted(true);
        handleRefreshAssets();
        setSuccessMessage('COMPLETED SUCCESFULLY');
      } else {
        setErrorMessage("ERROR ON FETCHING DATA");
      }

      // console.log('Data submitted:', { userInstitutionLabel, userInstitutionCountry });
      setSuccessMessage('Completed successfully');
      setIsProcessing(false);
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Failed to process your request.');
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setSuccessMessage(null);
      setIsProcessing(false);
      setuserInstitutionLabel('');
      setuserInstitutionCountry(null);
      setTaskCompleted(false); // Reset the task completed state
    }
  }, [isOpen]);
  
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={() => !isProcessing && onClose()}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_institution_title')}</DialogTitle>
        <DialogContent>
          <Box sx={{ paddingBottom: '20px' }}>
            {errorMessage && (
              <div className="error-container">
                <div className="error-icon">!</div>
                <Typography className="error-text">{errorMessage}</Typography>
              </div>
            )}
            {successMessage && (
              <div className="success-container">
                <div className="success-icon">!</div>
                <Typography className="success-text">{successMessage}</Typography>
              </div>
            )}
          </Box>
          {!taskCompleted &&
            <Box>
              <TextField
                fullWidth
                label={t('institution_label')}
                variant="outlined"
                value={userInstitutionLabel}
                onChange={(e) => setuserInstitutionLabel(e.target.value)}
                style={{ marginBottom: 20 }}
                disabled={isProcessing}
              />
              <Autocomplete
                options={sortedCountries}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Country" />}
                value={userInstitutionCountry}
                onChange={(_, newValue) => setuserInstitutionCountry(newValue)}
                fullWidth
                disabled={isProcessing}
              />
            </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" disabled={isProcessing}>
            Close
          </Button>
          {!taskCompleted &&
            <Button onClick={handleProcessing} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export { ModalInstitutionCreate };
