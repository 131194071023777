import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { t } from './Util_format';
import { cellTextStyle } from './PageAssets';

export function TableAssetsBonds({ URLassetType, assets_open, onPageChange, searchTerm, category, base_currency, show_PL_in, handleOpenActionsModal }) {
  const [sortConfig, setSortConfig] = useState({ key: 'user_asset_label', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  
  const requestSort = (key, type) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, type });
  };

  const TableSort = (a, b, sortConfig) => {
    const { key, direction, type } = sortConfig;
    const resolvePath = (object, path, defaultValue) => {
      return path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object);
    };

    let valA = resolvePath(a, key, null);
    let valB = resolvePath(b, key, null);

    // Normalize values for sorting
    switch (type) {
      case 'currency':
        valA = valA ? parseCurrencyValue(valA) : 0;
        valB = valB ? parseCurrencyValue(valB) : 0;
        break;
      case 'number':
        valA = parseNumberValue(valA);
        valB = parseNumberValue(valB);
        break;
      case 'string':
        valA = valA ? valA.toLowerCase() : '';
        valB = valB ? valB.toLowerCase() : '';
        break;
    }

    // Sort logic
    if (valA < valB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (valA > valB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };

  function parseCurrencyValue(value) {
    const num = Number(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }

  function parseNumberValue(value) {
    const num = parseFloat(value);
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }


  const renderTableSort = (key, label, type) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key, type)}
    >
      {t(label)}
    </TableSortLabel>
  );

  if (!category) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_asset_category')}
      </Typography>
    );
  } else if (!Array.isArray(assets_open) || assets_open.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_assets_to_show')}
      </Typography>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedAssets = [...assets_open].sort((a, b) => {
    return TableSort(a, b, sortConfig);
  });

  const filteredAssets = sortedAssets.filter(asset => {
    // prevent error switching between shares and bank accounts
    if (!asset.user_asset_purchase_currency) {
      return false;
    }
    const combinedFields = [
      asset.user_asset_label,
      asset.user_ta_label,
      asset.dynamic_average_purchase_price,
    ].filter(Boolean).join(' ').toLowerCase(); // Filter out any undefined or null values and join them into a single string

    asset.table_last_value = parseFloat(asset.product_last_value[asset.user_asset_purchase_currency]).toLocaleString('en-US', { style: 'currency', currency: asset.user_asset_purchase_currency, minimumFractionDigits: 2, maximumFractionDigits: 6 });


    asset.table_total_value = parseFloat((asset.product_last_value[base_currency])).toLocaleString('en-US', { style: 'currency', currency: base_currency });

    // console.log(asset);

    asset.table_total_PL = parseFloat(asset.PL_unit[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });
    // Ensure the purchase price is not zero to avoid division by zero
    asset.table_percentage_PL = asset.dynamic_average_purchase_price !== 0
      ? parseFloat(((asset.product_last_value[asset.user_asset_product_currency] - asset.dynamic_average_purchase_price) / (asset.dynamic_average_purchase_price * asset.dynamic_current_quantity)) * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      : "0.00";
    return combinedFields.includes(searchTerm.toLowerCase());
  });

  const displayedAssets = filteredAssets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const evenRowStyle = {
    backgroundColor: '#F0F0F0',
  };

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell data-key='user_asset_label' data-type='string'>
              {renderTableSort('user_asset_label', t('product'), 'string')}
            </TableCell>
            <TableCell data-key='user_ta_label' data-type='string' style={{ width: '180px' }}>
              {renderTableSort('user_ta_label', t('brokerage_account'), 'string')}
            </TableCell>
            <TableCell data-key='dynamic_average_purchase_price' style={{ textAlign: 'right', width: '120px' }}>
              {t('purchase_price')}
            </TableCell>
            <TableCell data-key='product_last_value' style={{ textAlign: 'right', width: '120px' }}>
              {t('last_value')}
            </TableCell>
            <TableCell data-key='table_total_value' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_total_value', t('total_value'), 'currency')}
            </TableCell>
            <TableCell data-key='table_total_PL' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {show_PL_in === 'PERCENTAGE' ? renderTableSort('table_percentage_PL', t('pl_in_percentage'), 'number') : renderTableSort('table_total_PL', t('total_PL'), 'currency')}
            </TableCell>
            {/* Add additional headers as needed */}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayedAssets.map((asset) => (
            <TableRow key={asset.user_asset_id} onClick={() => handleOpenActionsModal(asset)} style={{ cursor: 'pointer' }}>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle }}>{asset.user_asset_label}</TableCell>
              <TableCell style={{ ...cellTextStyle }}>{asset.user_ta_label}</TableCell>
              <TableCell style={{ textAlign: 'right', ...cellTextStyle }}>{parseFloat(asset.dynamic_average_purchase_price).toLocaleString('en-US', { style: 'currency', currency: asset.user_asset_purchase_currency })}</TableCell>
              <TableCell style={{
                textAlign: 'right',
                ...cellTextStyle,
              }}>
                {asset.table_last_value}
              </TableCell>
              <TableCell style={{
                textAlign: 'right',
                paddingRight: '40px',
                ...cellTextStyle,
              }}>
                {asset.table_total_value}
              </TableCell>
              <TableCell style={{
                textAlign: 'right',
                paddingRight: '40px',
                ...cellTextStyle,
                ...(asset.PL_unit[base_currency] < 0 ? loss_color : gain_color),
              }}>
                {show_PL_in === 'PERCENTAGE' ? asset.table_percentage_PL : asset.table_total_PL}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        style={{ marginTop: '20px' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredAssets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom' />
    </div>
  );
}
