import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, TextField } from '@mui/material';
import { t } from './Util_format';
import { theme } from '@style/styled.js';
import { fetchRenameLiability, fetchUpdateLiabilityBalance } from './Util_API_calls';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NumericFormat } from 'react-number-format';
import { ThemeProvider } from '@mui/material/styles';

function ModalLiabilitiesActions({ onClose, isOpen, liabilityType, liability, handleRefreshLiabilities }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [renameMode, setRenameMode] = useState(false);
  const [updateBalanceMode, setUpdateBalanceMode] = useState(false);
  const [newLiabilityName, setNewLiabilityName] = useState('');
  const [newLiabilityBalance, setNewLiabilityBalance] = useState('');
  const [modalLiabilityLabel, setModalLiabilityLabel] = useState('');
  const [localLiabilityBalance, setLocalLiabilityBalance] = useState('');

  const balanceInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && liability) {
      setModalLiabilityLabel(liability.user_liability_label || 'Unknown');
      setLocalLiabilityBalance(liability.dynamic_balance[liability.user_liability_currency] || liability.user_asset_balance);
    }
    if (!isOpen) {
      resetState();
    }
  }, [isOpen, liability]);

  const resetState = () => {
    setIsProcessing(false);
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setNewLiabilityName('');
    setNewLiabilityBalance('');
    setModalLiabilityLabel('');
    setLocalLiabilityBalance('');
  };

  const handleCancelUpdateBalance = () => {
    setErrorMessage('');
    setUpdateBalanceMode(false);
  };

  const handleUpdateBalanceClick = () => {
    setErrorMessage('');
    setUpdateBalanceMode(true);
    setRenameMode(false);
    setNewLiabilityBalance(newLiabilityBalance || localLiabilityBalance);
  };

  const handleSaveNewBalance = async () => {
    setErrorMessage('');
    setIsProcessing(true);

    if (liability && liability.user_liability_id) {
      try {
        await fetchUpdateLiabilityBalance(liabilityType, liability.user_liability_id, newLiabilityBalance);
        setLocalLiabilityBalance(newLiabilityBalance); // Update local balance
        handleRefreshLiabilities();
      } catch (error) {
        console.error('Error updating liability balance', error);
      }
    } else {
      console.error('Liability ID is missing');
    }
    setIsProcessing(false);
    setUpdateBalanceMode(false);
  };

  const handleRenameClick = () => {
    setErrorMessage('');
    setRenameMode(true);
    setUpdateBalanceMode(false);
    setNewLiabilityName(newLiabilityName || liability.user_liability_label);
  };

  const handleCancelRename = () => {
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
  };

  const handleSaveNewName = async () => {
    setIsProcessing(true);

    if (liability && liability.user_liability_id) {
      try {
        await fetchRenameLiability(liabilityType, liability.user_liability_id, newLiabilityName);
        setModalLiabilityLabel(newLiabilityName);
        handleRefreshLiabilities();
      } catch (error) {
        console.error('Error renaming liability', error);
      }
    } else {
      console.error('Liability ID is missing');
    }
    setIsProcessing(false);
    setRenameMode(false);
  };

  const handleOpenDeleteLiability = () => {
    const balance = parseFloat(localLiabilityBalance);
    if (balance !== 0) {
      setErrorMessage(t('cannot_close_liability_with_balance'));
    } else {
      navigate(`/liabilities/${liabilityType}/delete/${liability.user_liability_id}`);
      onClose();
    }
  };

  const closeActionModal = () => {
    if (!isProcessing) {
      onClose();
      navigate(`/liabilities/${liabilityType}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={isProcessing ? null : onClose}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown={isProcessing}
      >
        <DialogTitle className="modal_title">
          {modalLiabilityLabel}<br />{liability.user_liability_id}
        </DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        <DialogContent>
          <List>
            {(liabilityType === 'creditcards' || liabilityType === 'others') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {renameMode ? (
                  <>
                    <TextField
                      label={t("liability_name")}
                      variant="outlined"
                      value={newLiabilityName}
                      onChange={(e) => setNewLiabilityName(e.target.value)}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                      disabled={isProcessing}
                    />
                    <Button
                      onClick={handleCancelRename}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveNewName}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleRenameClick} disabled={isProcessing}>
                    <ListItemText primary={t('rename_liability')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(liabilityType === 'creditcards' || liabilityType === 'others') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {updateBalanceMode ? (
                  <>
                    <NumericFormat
                      value={newLiabilityBalance}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      customInput={TextField}
                      label={t('new_balance')}
                      variant="outlined"
                      name="new_balance"
                      decimalScale={2}
                      fullWidth
                      inputRef={balanceInputRef}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        setNewLiabilityBalance(value);
                      }}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                    />
                    <Button
                      onClick={handleCancelUpdateBalance}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewBalance}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateBalanceClick} disabled={isProcessing}>
                    <ListItemText primary={t('update_balance')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {liabilityType === 'creditcards' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteLiability} disabled={isProcessing}>
                  <ListItemText primary={t('close_liabilities_creditcards')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {liabilityType === 'others' && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteLiability} disabled={isProcessing}>
                  <ListItemText primary={t('close_liabilities_other')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: 'gray',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkgray',
              },
            }}
            onClick={closeActionModal}
            variant="contained"
            disabled={isProcessing}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ModalLiabilitiesActions;
