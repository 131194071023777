import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, createTheme, ThemeProvider, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import translations from '@translations/translations.json';
import { getCookieValue } from './App';
import { format } from 'date-fns';
import config from './config/env.json';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import logopng from '@images/logo.png';
import pdf_icon from '@images/pdf_icon.svg';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { it } from 'date-fns/locale';
import { fetchPageData, fetchCardTransactions } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { number_to_italian_currency } from './Util_format';


const theme = createTheme({
  palette: {
    primary: {
      main: '#2a9461',
    },
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {

          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#34403A',
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',

    body1: {
      fontSize: '1rem',
    },

  },
});

const iconStyle = {
  color: '#2a9461',
  height: '24px',
  width: '24px'
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px'
};

const textStyle_small_light = {
  fontWeight: 500,
  color: '#34403A',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const exportPDF = (transactions, filters) => {
  if (!Array.isArray(transactions)) {
    console.error('Invalid transactions data. Must be an array.');
    return;
  }

  // Filtering transactions based on the provided filters
  const filteredTransactions = transactions.filter(tx => {
    const transactionDate = new Date(tx.transaction_date_time);
    const isDateInRange = transactionDate >= new Date(filters.dateRange.from) && transactionDate <= new Date(filters.dateRange.to);
    const isDescriptionMatch = tx.description.toLowerCase().includes(filters.searchTerm.toLowerCase());
    // Add more conditions here if you want to filter by account or other fields
    return isDateInRange && isDescriptionMatch;
  });

  // Sorting filtered transactions
  const sortedFilteredTransactions = filteredTransactions.map(tx => {
    const date = new Date(tx.transaction_date_time);
    const formattedDate = !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy HH:mm', { locale: it }) : 'Invalid Date';
    return {
      ...tx,
      date: formattedDate,
      originalDate: date // Add the original Date object for sorting
    };
  }).sort((a, b) => a.originalDate - b.originalDate); // Sorting by the original Date object


  const filterText = `Filters Applied: 
    Account: ${filters.account || 'None'}, 
    Search Term: ${filters.searchTerm || 'None'}, 
    Date From: ${filters.dateRange && format(filters.dateRange.from, 'dd/MM/yyyy') || 'None'}, 
    Date To: ${filters.dateRange && format(filters.dateRange.to, 'dd/MM/yyyy') || 'None'}`;

  const headerFontSize = 22 * 0.6;
  const headerText1 = 'CASA REALE HOLDING S.P.A.';
  const headerText2 = 'CENTRO DIREZIONALE VIA GIOVANNI PORZIO';
  const headerText3 = 'SNC 80143 NAPOLI (NA)';
  const pdf = new jsPDF();

  const logoWidthMM = 47 * 0.264583;
  const logoHeightMM = 75 * 0.264583;

  // Convert transaction dates to Date objects and sort
  const sortedRows = transactions.map(tx => {
    const date = new Date(tx.transaction_date_time);
    const formattedDate = !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy HH:mm', { locale: it }) : 'Invalid Date';

    return {
      ...tx,
      date: formattedDate,
      originalDate: date // Add the original Date object for sorting
    };
  }).sort((a, b) => a.originalDate - b.originalDate); // Sorting by the original Date object

  const columns = [
    { header: 'Data', dataKey: 'date' },
    { header: 'Descrizione', dataKey: 'description' },
    { header: 'Importo', dataKey: 'amount' },
  ];

  autoTable(pdf, {
    columns: columns,
    body: sortedFilteredTransactions,
    margin: { top: logoHeightMM + 20 },
    didDrawPage: (data) => {
      // Add company logo to each page
      pdf.addImage(logopng, 'PNG', 15, 10, logoWidthMM, logoHeightMM);

      if (pdf.internal.getNumberOfPages() === 1) {
        // Coordinates for the top right text
        const pageWidth = pdf.internal.pageSize.getWidth();
        const rightMargin = 18; // You can adjust this margin

        // Draw the address lines
        pdf.setFontSize(headerFontSize);
        pdf.text(headerText1, pageWidth - pdf.getTextWidth(headerText1) - rightMargin, 15);
        pdf.text(headerText2, pageWidth - pdf.getTextWidth(headerText2) - rightMargin, 20);
        pdf.text(headerText3, pageWidth - pdf.getTextWidth(headerText3) - rightMargin, 25);

        // Draw the filter text below the address lines
        const filterTextY = 30; // Adjust the vertical position as needed
        pdf.setFontSize(12);
        const longestLine = filterText.split('\n').reduce((longest, line) => longest.length > line.length ? longest : line, '');
        const filterTextX = pageWidth - pdf.getTextWidth(longestLine) - rightMargin;
        pdf.text(filterText, filterTextX, filterTextY);
      }

      // Add page number at the bottom center of each page
      let str = 'Pagina ' + pdf.internal.getNumberOfPages();
      let pageSize = pdf.internal.pageSize;
      let pageWidth = pageSize.getWidth ? pageSize.getWidth() : pageSize.width;
      pdf.setFontSize(10);
      pdf.text(str, pageWidth / 2, pageSize.height - 10, {
        align: 'center',
      });
    },
    styles: {
      overflow: 'linebreak',
      fontSize: 11,
    },
    headStyles: {
      fillColor: '#2a9461',
      textColor: '#FFFFFF',
      halign: 'center',
      fontStyle: 'bold',
    },
    columnStyles: {
      date: { cellWidth: 60 },
      amount: { cellWidth: 40, halign: 'right' },
    },
    theme: 'grid',
    startY: pdf.internal.pageSize.getHeight() - pdf.internal.pageSize.getHeight() + (logoHeightMM + 40),
    showHead: 'everyPage',
  });

  pdf.save('statement.pdf');
};


function TransactionsTable({ transactions, onPageChange, dateRange, searchTerm, account }) {
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'desc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Check if an account is selected and transactions is an array and not empty
  if (!account) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_account')}
      </Typography>
    );
  } else if (!Array.isArray(transactions) || transactions.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_transactions_to_show')}
      </Typography>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()) ? format(date, 'dd/MM/yyyy', { locale: it }) : 'Invalid Date';
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = [...transactions].sort((a, b) => {
    // Convert transaction date strings to Date objects
    const dateA = new Date(a.transaction_date_time);
    const dateB = new Date(b.transaction_date_time);

    if (sortConfig.key === 'date') {
      // Sort by date
      if (sortConfig.direction === 'asc') {
        return dateA - dateB; // Ascending order
      } else {
        return dateB - dateA; // Descending order
      }
    }

    // Sorting by other fields can be added here
    // Example: Sorting by amount
    if (sortConfig.key === 'amount') {
      if (parseFloat(a.amount) < parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (parseFloat(a.amount) > parseFloat(b.amount)) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
    }

    // Add additional sorting logic for other fields if needed

    return 0; // Default return for equal items or unhandled sort keys
  });



  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderTableSort = (key, label) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key)}
      className='table_header'>
      {label}
    </TableSortLabel>
  );

  const filteredTransactions = sortedTransactions.filter(transaction => {
    const transactionDate = new Date(transaction.transaction_date_time);
    return transactionDate >= dateRange.from && transactionDate <= dateRange.to &&
      transaction.description.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const displayedTransactions = filteredTransactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell>{renderTableSort('date', t('date'))}</TableCell>
            <TableCell>{renderTableSort('description', t('description'))}</TableCell>
            <TableCell align="right">{renderTableSort('amount', t('amount'))}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedTransactions.map((transaction) => (
            <TableRow key={transaction.transaction_id}>
              <TableCell className="table_body">{formatDate(transaction.transaction_date_time)}</TableCell>
              <TableCell className="table_body">{transaction.description}</TableCell>
              <TableCell className="table_body" align="right">{number_to_italian_currency(transaction.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        style={{
          marginTop: '20px'
        }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTransactions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom'
      />
    </div>
  );
}

function Page_Transactions({ onDataChange, ...props }) {
  const [account, setAccount] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [bankAccountID, setbankAccountID] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  // Other state and variables
  const [dateRange, setDateRange] = useState({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const handleDateChange = (name) => (newValue) => {
    setDateRange({ ...dateRange, [name]: newValue });
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const [userAccounts, setUserAccounts] = useState([]);

  // Inside the get_basic_user_data function, after fetching the data
  const processAccountsData = (userData) => {
    const cardAccounts = userData.user_accounts.card_accounts.map(account => ({
      name: `Card  **** **** **** ${account.card_last_four_digits}`, // Masked PAN
      card_id: `${account.card_id}`,
      type: 'card'
    }));

    const currentAccounts = userData.user_accounts.current_accounts.map(account => ({
      name: `Bank Account - ${account.account_number}`,
      account_id: `${account.account_id}`,
      type: 'account'
    }));

    const allAccounts = [...currentAccounts, ...cardAccounts];
    setUserAccounts(allAccounts);
  };

  useEffect(() => {
    get_basic_user_data();
  }, []);

  const handleChange = (event) => {
    setAccount(event.target.value);
  };

  const accountIcon = (type) => {
    return type === 'account' ?
      <AccountBalanceIcon style={iconStyle} /> :
      <CreditCardIcon style={iconStyle} />;
  };

  const getSelectedAccountType = () => {
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    return selectedAccount ? selectedAccount.type : null;
  };

  useEffect(() => {
    if (!account) {
      // console.log("No account selected, skipping data fetch.");
      return;
    }

    // console.log("useEffect for data fetching triggered");
    const accountType = getSelectedAccountType();
    // console.log("Selected Account Type:", accountType);
    if (accountType === 'card') {
      // console.log("Account type is card. Calling get_card_data...");
      get_card_data();
    } else if (accountType === 'account') {
      // console.log("Account type is bank account. Calling get_bank_account_data...");
      get_bank_account_data();
    }
  }, [account, userAccounts]);

  const get_card_data = async () => {
    // console.log("get_card_data called");
    // console.log("Current account selection:", account);
    // console.log("Available user accounts:", userAccounts);

    // Find the account using the name, then extract the card_id
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for card data fetch");
      return;
    }

    // console.log("Selected Account for Card Data:", selectedAccount);

    // Use selectedAccount.card_id for fetching card data
    const monthsToShow = 3; // Adjust as needed
    const transactionType = 'ALL'; // Adjust as needed

    setIsLoading(true);
    try {
      const data = await fetchCardTransactions(selectedAccount.card_id, monthsToShow, transactionType);
      // console.log("Fetched Data:", data);
      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        // console.log("No transactions found for account: ", account);
        setTransactions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching card data:', error);
      setIsLoading(false);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_bank_account_data = async () => {
    // console.log("get_bank_account_data called");
    // console.log("Current account selection:", account);
    // console.log("Available user accounts:", userAccounts);

    // Find the account using the name, then extract the account_id or any unique identifier
    const selectedAccount = userAccounts.find(acc => acc.name === account);
    if (!selectedAccount) {
      console.error("No selected account found for bank account data fetch");
      return;
    }

    // console.log("Selected Account for Bank Account Data:", selectedAccount);

    // Use selectedAccount's account_id for fetching bank account data
    const monthsToShow = 3; // Adjust as needed
    const transactionType = 'ALL'; // Adjust as needed

    setIsLoading(true);
    try {
      const data = await fetchPageData(selectedAccount.account_id, monthsToShow, transactionType);
      // console.log("Fetched Data:", data);
      if (data && data.transactions) {
        setTransactions(data.transactions);
      } else {
        // console.log("No transactions found for account: ", account);
        setTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching bank account data:', error);
      setTransactions([]);
    } finally {
      setIsLoading(false); // Ensure isLoading is set to false in both success and failure cases
    }
  };

  const get_basic_user_data = async () => {
    setIsLoading(true);
    try {
      const data = await fetchPageData('transactions');

      if (data) {
        // Call processAccountsData here to process and set user accounts
        processAccountsData(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {/* This Grid item appears to be empty. If not needed, consider removing it. */}
          <Grid item xs={12}></Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body1" style={{ ...textStyle_small_light }}>
                    {t('bank_transactions')}
                  </Typography>
                  <DescriptionIcon color="action" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '40px' }}>
                  <FormControl style={{ marginRight: '10px', width: '450px' }}>
                    <InputLabel id="account-select-label" className="transactions_input-label">
                      {t('select_account')}
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={account}
                      label={t('select_account')}
                      onChange={handleChange}
                      color="primary"
                      IconComponent={ListIcon}
                      className="transactions_select"
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          {userAccounts.find((item) => item.name === selected) ? accountIcon(userAccounts.find((item) => item.name === selected).type) : null}
                          <Typography variant="body2" noWrap style={{ ...textStyle_small_light, marginLeft: '8px' }}>
                            {selected}
                          </Typography>
                        </Box>
                      )}
                    >
                      {userAccounts.map((item) => (
                        <MenuItem key={item.name} value={item.name} style={menuItemStyle}>
                          <ListItemIcon>
                            {accountIcon(item.type)}
                          </ListItemIcon>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    style={{ flexGrow: 2, marginRight: '10px' }}
                    name="search_filter_unique"
                    label={t('search_filter')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="no-autofill"
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                    
                    <div style={{ width: '160px', marginRight: '10px' }}>
                      <DatePicker
                        label={t('date_from')}
                        value={dateRange.from}
                        onChange={handleDateChange('from')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: '160px', marginRight: '10px' }}>
                      <DatePicker
                        label={t('date_to')}
                        value={dateRange.to}
                        onChange={handleDateChange('to')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                    </div>
                  </LocalizationProvider>

                  {/* Styled Export to PDF button */}
                  <button
                    onClick={() => exportPDF(transactions, { account, searchTerm, dateRange })}
                    style={{
                      paddingLeft: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right',
                      backgroundColor: 'transparent',
                    }}
                    aria-label="Export to PDF" // Accessibility label
                  >
                    <img src={pdf_icon} alt="PDF" style={{ height: '30px' }} />
                  </button>


                </div>
                {isLoading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>
                    {/* Add this button and pass the transactions state to the exportPDF function */}
                    <TransactionsTable
                      key={searchTerm}
                      transactions={transactions}
                      onPageChange={handlePageChange}
                      dateRange={dateRange}
                      searchTerm={searchTerm}
                      account={account}
                      onExportPDF={() => exportPDF(transactions, { account, searchTerm, dateRange })}
                    />
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Transactions;