import React, { useState } from 'react';
import { Dialog, DialogTitle, Button, Typography, ThemeProvider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { theme } from '@style/styled.js';
import config from './config/env.json';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CollectionsIcon from '@mui/icons-material/Collections';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HomeIcon from '@mui/icons-material/Home';
import SavingsIcon from '@mui/icons-material/Savings';
import SecurityIcon from '@mui/icons-material/Security';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const Icons = {
  ListAltIcon,
  AccountBalanceIcon,
  ShowChartIcon,
  BusinessCenterIcon,
  CollectionsIcon,
  BarChartIcon,
  CreditCardIcon,
  CurrencyBitcoinIcon,
  TrendingUpIcon,
  MoreHorizIcon,
  HomeIcon,
  SavingsIcon,
  SecurityIcon,
  StackedLineChartIcon,
  DirectionsCarIcon,
};

const iconStyle = {
  marginRight: '8px',
  height: '24px',
  width: '24px',
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '10px 20px',
  border: '1px solid #ccc',
  backgroundColor: '#f5f5f5',
  color: '#333',
  textTransform: 'none',
  fontWeight: 'bold',
  margin: '5px',
  height: '75px', // Add this line to set the button height
};


const textStyle = {
  flexGrow: 1,
  textAlign: 'center',
};

function ModalAssetCreateAll({ onClose, isOpen, assetType }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const handleNavigate = (userAssetType) => {
    onClose();
    switch (userAssetType) {
      case 'bankaccounts':
      case 'credits':
        navigate(`/assets/${userAssetType}/create`);
        break;
      case 'shares':
      case 'commodities':
        navigate(`/assets/${userAssetType}/buy`);
        break;
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_asset_title_' + assetType)}</DialogTitle>
        <div style={{ padding: '20px', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px' }}>
          {config.ASSET_CATEGORIES.map((category) => {
            const IconComponent = Icons[category.icon];
            if (category.user_asset_type === 'all') {
              return null;
            }
            return (
              <Button
                key={category.user_asset_type}
                variant="contained"
                style={buttonStyle}
                onClick={() => handleNavigate(category.user_asset_type)}
              >
                <IconComponent style={iconStyle} />
                <Typography style={textStyle}>
                  {t('categories_assets_' + category.user_asset_type)}
                </Typography>
              </Button>
            );
          })}
        </div>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <div className="success-icon">!</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
      </Dialog>
    </ThemeProvider>
  );
}

export default ModalAssetCreateAll;
