import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from './Util_format';
import { theme } from '@style/styled.js';
import { NumericFormat } from 'react-number-format';
import config from './config/env.json';
import { fetchCreateAssetsCommodities } from './Util_API_calls';

function ModalAssetBuyCommodities({ onClose, isOpen, assetType, trading_accounts, handleRefreshAssets }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedTa, setSelectedTa] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [productCode, setProductCode] = useState('');
  const [purchaseCurrency, setPurchaseCurrency] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const debounceRef = useRef(null);
  const [number_of_commodities, setNumberOfCommodities] = useState('');
  const [price_paid_per_share, setPricePaidPerShare] = useState('');
  const [taskCompleted, setTaskCompleted] = useState(false);

  useEffect(() => {
    if (filter) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(async () => {
        setLoading(true);
        //  const results = await fetchFindCommodities(filter);
        let results = Array('gold','silver')
        // ('API call made:', results); // Check what the API returns
        if (results && results.status === 'success') {
          setSearchResults(results.data);
        } else {
          setSearchResults([]);
        }
        setLoading(false);
      }, 1000);
    } else {
      setSearchResults([]);
    }
    return () => clearTimeout(debounceRef.current);
  }, [filter]);

  const handleProcessing = async () => {
    try {
      setErrorMessage('');
      if (!selectedTa || !selectedTa.user_ta_id) {
        setErrorMessage(t('please_select_ta'));
        return false;
      }
      if (!productCode) {
        setErrorMessage(t('please_select_company'));
        return false;
      }
      if (!number_of_commodities) {
        setErrorMessage(t('please_enter_number_of_commodities'));
        return false;
      }
      if (!price_paid_per_share) {
        setErrorMessage(t('please_enter_price_per_share'));
        return false;
      }
      if (!purchaseDate) {
        setErrorMessage(t('please_select_the_purchase_date'));
        return false;
      }

      setIsProcessing(true); // Start processing

      // console.log('Creating new asset:', productCode, selectedTa.user_ta_id, price_paid_per_share, number_of_commodities, purchaseDate.toISOString().split('T')[0], purchaseCurrency);
      // return true;
      const data = await fetchCreateAssetsCommodities(productCode, selectedTa.user_ta_id, price_paid_per_share, number_of_commodities, purchaseDate.toISOString().split('T')[0], purchaseCurrency);
      if (data) {
        setTaskCompleted(true);
        handleRefreshAssets();
        setSuccessMessage('COMPLETED SUCCESSFULLY');
      } else {
        setErrorMessage("ERROR ON FETCHING DATA");
      }

    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  // Reset modal state when it opens
  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setSuccessMessage(null);
      setIsProcessing(false);
      setSelectedTa(null);
      setProductCode('');
      setPurchaseCurrency('');
      setNumberOfCommodities('');
      setPricePaidPerShare('');
      setPurchaseDate(new Date()); // Reset to current date or use null for no selection
      setTaskCompleted(false);
    }
  }, [isOpen]);


  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_asset_title_' + assetType)}</DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
        {!taskCompleted && (
          <>
            <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' }}>

              <FormControl>
                <Autocomplete
                  freeSolo
                  disableClearable
                  options={searchResults}
                  getOptionLabel={(option) => `${option.product_label || ''} (${option.product_code || ''})`} // Displayed in the input after selection
                  renderOption={(props, option) => {
                    let country = option.product_country.replace(/\s/g, '_').toLowerCase() || '';
                    const countryIconUrl = `/flags/${country}.svg`;

                    return (
                      <li {...props}>
                        <img src={countryIconUrl} alt={country} style={{ width: 24, height: 24, marginRight: 10 }} />
                        {`${option.product_label} (${option.product_code})`}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search and select share"
                      variant="outlined"
                      onChange={(e) => {
                        setFilter(e.target.value);
                        if (!e.target.value) {
                          setSearchResults([]); // Clear results if input is cleared
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setProductCode(newValue?.product_code || ''); // Save company code
                    setPurchaseCurrency(newValue?.product_currency || ''); // Assuming you also want to manage the currency
                    // console.log(`Saved the company code: ${newValue?.product_code}`);
                  }}
                  style={{ width: '560px' }}
                />

              </FormControl>
            </ListItem>
            <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' }}>
              <Autocomplete
                options={trading_accounts}
                getOptionLabel={(option) => option.user_ta_label || ''}
                renderInput={(params) => <TextField {...params} label={t('select_brokerage_account')} variant="outlined" />}
                value={selectedTa}
                onChange={(event, newValue) => {
                  setSelectedTa(newValue);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText primary={t('select_ta_brokerage')} style={{ paddingLeft: '20px', width: '400px' }} />
            </ListItem>
            <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' }}>
              <NumericFormat
                customInput={TextField}
                label={t('number_of_commodities')}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { value } = values;
                  setNumberOfCommodities(value);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText primary={t('number_of_commodities_to_buy')} style={{ paddingLeft: '20px', width: '400px' }} />
            </ListItem>
            <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' }}>
              <NumericFormat
                customInput={TextField}
                label={t('price_paid_per_share')}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { value } = values;
                  setPricePaidPerShare(value);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText primary={t('price_paid_per_share')} style={{ paddingLeft: '20px', width: '400px' }} />
            </ListItem>
            <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns} style={{ width: '330px' }} >
                <DatePicker
                  label={t('opening_date')}
                  value={purchaseDate}
                  onChange={(newValue) => {
                    setPurchaseDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))}
                />
              </LocalizationProvider>
              <ListItemText primary={t('data_tracked_from_this_day_onwards')} style={{ paddingLeft: '20px', width: '400px' }} />
            </ListItem>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" disabled={isProcessing}>
            {t('close')}
          </Button>
          {!taskCompleted && (
            <Button onClick={handleProcessing} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ModalAssetBuyCommodities;