import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, Typography, TextField } from '@mui/material';
import { t } from './Util_format';
import { fetchCloseAssetRealestate } from './Util_API_calls';
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function ModalAssetDeleteCloseRealEstate({ onClose, isOpen, asset, handleRefreshAssets }) {
  const [action, setAction] = useState(''); // No default selection
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [IsProcessing, setIsProcessing] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleDelete = async () => {
    if (!action) {
      setErrorMessage(t('please_fill_all_mandatory_inputs'));
      return;
    }
    if (action === 'delete') {
      setIsProcessing(false); // Stop loading as operation will not proceed
      setErrorMessage(t('feature_under_construction')); // Customizable message
      return; // Early return to prevent further processing
    }
    setErrorMessage('');
    setIsProcessing(true);
    setIsProcessing(true);
    const data = await fetchCloseAssetRealestate(asset.user_asset_id, format(selectedDate, 'yyyy-MM-dd'));
    if (data) {
      setIsProcessing(false);
      setTaskCompleted(true);
      handleRefreshAssets();
      setSuccessMessage(t('close_asset_realestate_success_messge'));
    } else {
      setErrorMessage(t('unknown_api_error'));
      setIsProcessing(false);
    }

  };

  const handleClose = () => {
    if (!IsProcessing) {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t('choose_an_action_for')} "{asset?.user_asset_label}"
        {errorMessage && (
          <div className="error-container">
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        )}
        {successMessage && (
          <div className="success-container">
            <div className="success-icon">!</div>
            <Typography className="success-text">{successMessage}</Typography>
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        {!taskCompleted && (
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="account action"
              name="accountAction"
              value={action}
              onChange={(event) => setAction(event.target.value)}
              style={{ marginBottom: '10px' }} // Space between choices
            >
              <FormControlLabel
                value="close"
                control={<Radio />}
                label={t('close_realestate_option_text')}
              />
              <FormControlLabel
                value="delete"
                control={<Radio />}
                label={t('delete_realestate_option_text')}
                style={{ marginTop: '12px' }} // Additional space between the options
              />
            </RadioGroup>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t("date_of_sale")}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          disabled={IsProcessing}
        >
          {t('close')}
        </Button>

        {!taskCompleted && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleDelete}
            disabled={IsProcessing}
          >
            {t('confirm')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ModalAssetDeleteCloseRealEstate;
