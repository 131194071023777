import React, { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Select, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, createTheme, ThemeProvider, Table, TableBody, TableCell, TextField, TableHead, TableRow, TableSortLabel, } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a9461',
    },
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {

          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#34403A',
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',

    body1: {
      fontSize: '1rem',
    },

  },
});

function PageHome({ onDataChange, ...props }) {

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          {/* This Grid item appears to be empty. If not needed, consider removing it. */}
          <Grid item xs={12}></Grid>

          <Grid item xs={12} sm={12}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
Under construction - Funziona solo il menu Assets (in parte)
            </Card>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default PageHome;