import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, AppBar, Drawer, IconButton, List, Toolbar, Typography, ThemeProvider, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { getCookieValue, changeLanguage, } from './App';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { t } from './Util_format';
import ModalReadMessage from './ModalReadMessage';
import { isBusinessAccount, markMessageAsRead } from './Util_API_calls'; // Adjust the path as necessary
import config from './config/env.json';
import flag_it from '@images/flag_it.svg';
import flag_en from '@images/flag_en.svg';
import flag_fr from '@images/flag_fr.svg';
import flag_cn from '@images/flag_cn.svg';

import { theme, NotificationItem, NotificationsSidebarTitle, LoggedInTopLeftLogo, TitleListItem, LoggedInCentralContainer, StructureLeftMenuItem } from '@style/styled.js';

function StructureLogged({ children, APIPageData = {} }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerreduced, setdrawerreduced] = useState(false);
  const [open, setOpen] = useState(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const currentFlag = (() => {
    const lang = getCookieValue('language');
    switch (lang) {
      case 'en':
        return flag_en;
      case 'fr':
        return flag_fr;
      case 'cn':
        return flag_cn;
      case 'it':
      default:
        return flag_it;
    }
  })();

  // Use useEffect to update the email state when APIPageData.details.email is available

  // check if maintenance mode is on
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (config.VERIFY_MAINTENANCE_MODE !== 'false') {
        // console.error('NO MAINTENANCE MODE CHECK');
        return;
      }
      fetch(config.PUBLIC_BUCKET_NAME + '/systems_health/api.txt')
        .then((response) => {
          // Check if the file was not found and exit early if so
          if (response.status === 404) {
            console.log('Maintenance mode file not found. Doing nothing.');
            return;
          }
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then((data) => {
          // If there's no data, do nothing
          if (!data) return;

          const lines = data.trim().split('\n'); // Trimming and splitting by new line
          const startTime = new Date(lines[0] + 'Z'); // Adding 'Z' to indicate UTC
          const endTime = new Date(lines[1] + 'Z'); // Adding 'Z' to indicate UTC
          const currentTime = new Date(); // Current time in UTC

          // Check if current time is within the maintenance window
          if (currentTime >= startTime && currentTime <= endTime) {
            navigate('/maintenance');
            console.log('We are currently in the middle of a maintenance outage.');
          } else {
            // console.log('We are not currently in a maintenance outage.');
          }
        })
        .catch((error) => {
          if (error.message.includes('404')) {
            // File not found, do nothing
          } else {
            console.error('Failed to fetch data:', error);
          }
        });
    }, 5000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, [navigate]);


  const handleLanguageChange = (lang) => {
    handleClose(); // Close the menu
    changeLanguage(lang); // Change the language
  };

  const handleCloseMessageModal = () => {
    setIsMessagesModalOpen(false);
  };


  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  useEffect(() => {

    // This will safely handle the case when APIPageData is null or undefined
    const count = APIPageData?.messages
      ? APIPageData.messages.filter(msg => !msg.is_read).length
      : 0;

    setUnreadMessagesCount(count);
  }, [APIPageData]); // Dep


  const handleLangMenuClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const handleGoToProfile = () => {
    navigate('/profile');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleGoToHome = () => {
    navigate('/home');
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint

  // if the cookie is not present, redirect to the login page
  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
      navigate('/login');
    } else {
      const loginOtpCompleted = getCookieValue('Loginotp_completed');
      if (loginOtpCompleted !== 'true') {
        const isPushMessage = getCookieValue('otp_delivery_method') === "push";
        if (isPushMessage) {
          navigate("/waiting_push");
        } else {
          navigate("/Loginotp");
        }
      }
    }
  }, [navigate]);

  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ msg_id: null, subject: '', content: '', created_at: '' });

  // Function to open the modal
  const openMessagesModal = () => {
    setIsMessagesModalOpen(true);
  };

  // Function to close the modal
  const closeMessagesModal = () => {
    setIsMessagesModalOpen(false);
  };

  const handleReadMessage = (message) => {
    markMessageAsRead(message.msg_id);

    // Update the is_read property in the original APIPageData.messages array
    const updatedMessages = APIPageData.messages.map(msg =>
      msg.msg_id === message.msg_id ? { ...msg, is_read: true } : msg
    );

    // Directly update APIPageData.messages with the new values
    APIPageData.messages = updatedMessages;

    setCurrentMessage({
      msg_id: message.msg_id,
      subject: message.subject,
      content: message.content,
      created_at: message.created_at
    });

    // Calculate new unread messages count
    const newUnreadCount = updatedMessages.filter(msg => !msg.is_read).length;
    setUnreadMessagesCount(newUnreadCount);

    openMessagesModal(); // Open the modal
  };


  const handleLogoClick = () => {
    navigate('/home');
  };

  return (
    <Box
      id="page_dashboard"
      style={{
        maxWidth: '2700px',
        width: '100%',
        margin: '0 auto',
        position: 'relative',
        minHeight: '100vh'
      }}
    >
      <ThemeProvider theme={theme}>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            style={{
              position: 'absolute',
              backdropFilter: 'blur(10px)',
              top: '18px',
              left: '20px',
              zIndex: 51
            }}
          >
            <MenuIcon />
          </IconButton>

        )}
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setdrawerreduced(!drawerreduced)}
            sx={{
              position: 'absolute',
              top: '40px',
              transform: 'translateY(-50%) scale(0.5)',
              left: drawerreduced ? '118px' : '228px',
              border: '1px dotted #AAAAAA',
              borderRadius: '50%',
              width: '48px !important',
              height: '48px !important',
              zIndex: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'DrawerReduceButton.main',
              '&:hover': {
                bgcolor: 'DrawerReduceButton_hover.main', // Keep the same background color on hover
              }
            }}
          >
            {drawerreduced ?
              <ArrowForwardIcon style={{ fontSize: '24px' }} /> : <ArrowBackIcon style={{ fontSize: '24px' }} />
            }
          </IconButton>
        )}
        <AppBar position="static" style={{
          marginLeft: isMobile ? 0 : (drawerreduced ? 100 : 220),
          width: isMobile ? 'calc(100vw)' : (drawerreduced ? 'calc(100% - 100px)' : 'calc(100% - 220px)'),
          paddingLeft: isMobile ? 60 : (drawerreduced ? 0 : 0)
        }}>
          <Toolbar
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '80px',
              gap: '8px',
            }}
          >
            {/* Languages */}
            <div style={{
              width: '48px',
              height: '48px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <IconButton
                onClick={handleLangMenuClick}
                sx={{
                  width: '48px',
                  height: '48px',
                  padding: 0,
                  position: 'absolute',
                  '&:hover': {
                    backgroundColor: 'transparent'  // Prevent hover background shift
                  }
                }}
              >
                <img
                  src={currentFlag}
                  alt="Italiano"
                  style={{
                    width: '36px',
                    height: '36px',
                    borderRadius: '10px',
                    display: 'block'
                  }}
                />
              </IconButton>
            </div>
            <Menu
              anchorEl={langMenuAnchorEl}
              keepMounted
              open={Boolean(langMenuAnchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'  // Changed from 'right' to 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'  // Changed from 'right' to 'center'
              }}
              sx={{
                '& .MuiPaper-root': {
                  marginTop: '4px',  // Add consistent margin
                  width: '130px'     // Fixed width instead of min/max
                }
              }}
            >
              {[
                { flag: flag_it, alt: "Italiano", lang: "it" },
                { flag: flag_en, alt: "English", lang: "en" },
                ...(config.FDT_BRAND_CODE === 'ipm' ? [
                  { flag: flag_fr, alt: "Français", lang: "fr" },
                  { flag: flag_cn, alt: "中文", lang: "cn" },
                ] : [])
              ].map(item => (
                <MenuItem
                  key={item.lang}
                  onClick={() => handleLanguageChange(item.lang)}
                  sx={{
                    height: '40px',
                    padding: '8px 16px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <img
                    src={item.flag}
                    alt={item.alt}
                    style={{
                      width: '24px',
                      height: '24px',
                      flexShrink: 0,
                      display: 'block'
                    }}
                  />
                  <Typography sx={{
                    fontSize: '0.875rem',
                    lineHeight: 1,
                    userSelect: 'none'
                  }}>
                    {item.alt}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>

            {/* Notifications */}
            <IconButton onClick={toggleDrawer(true)}>
              <Badge badgeContent={unreadMessagesCount} color="error">
                <NotificationsIcon sx={{ fontSize: 28, color: 'IconsInDropdown.main' }} />
              </Badge>
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
              <Box
                onClick={(e) => toggleDrawer(false)(e)}
                onKeyDown={(e) => toggleDrawer(false)(e)}
              >
                <Box style={{ padding: '20px' }}>
                  <NotificationsSidebarTitle>
                    {t('notifications')}
                  </NotificationsSidebarTitle>
                  {
                    APIPageData?.messages && APIPageData.messages.length > 0 ? (
                      APIPageData.messages
                        .slice()
                        .sort((a, b) => {
                          const dateA = new Date(a.created_at);
                          const dateB = new Date(b.created_at);
                          return dateB - dateA;
                        })
                        .map((message, index) => (
                          <NotificationItem
                            key={message.msg_id}
                            message={message}
                            handleReadMessage={handleReadMessage}
                            index={index}
                          />
                        ))
                    ) : (
                      <Typography>
                        {t('no_notifications')}
                      </Typography>
                    )
                  }
                </Box>
              </Box>
            </Drawer>

            {/* Account Menu */}
            <IconButton onClick={handleAccountMenuClick}>
              <AccountCircleIcon style={{ width: '28px', height: '28px', borderRadius: '5px' }} color="topmenu_icons" />
            </IconButton>
            <Menu
              anchorEl={accountMenuAnchorEl}
              keepMounted
              open={Boolean(accountMenuAnchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  minWidth: '120px',
                  maxWidth: '120px',
                },
              }}
            >
              <MenuItem onClick={handleGoToHome} style={{ padding: '8px 16px' }}>
                <HomeIcon color="leftmenu_icons" />
                <Typography sx={{ fontSize: '0.875rem' }}> {t("home")}</Typography>
              </MenuItem>
              <MenuItem onClick={handleGoToProfile} style={{ padding: '8px 16px' }}>
                <AccountCircle color="leftmenu_icons" />
                <Typography sx={{ fontSize: '0.875rem' }}> {t("profile")}</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout} style={{ padding: '8px 16px' }}>
                <LogoutIcon color="leftmenu_icons" />
                <Typography sx={{ fontSize: '0.875rem' }}> {t("logout")}</Typography>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? open : true}
          onClose={handleDrawerToggle}
          classes={{
            paper: drawerreduced ? "reduced-drawer" : "",
          }}
          sx={{
            width: drawerreduced ? '130px' : '240px',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerreduced ? '130px' : '240px',
              height: '100%',  // Change from 100vh to 100%
              boxSizing: 'border-box',
              position: 'absolute', // Keep as absolute within the relative container
              top: 0,
              left: 0,
              border: 'none'
            }
          }}
        >
          <List>
            <LoggedInTopLeftLogo $drawerreduced={drawerreduced} onClick={handleLogoClick} />
            {!drawerreduced ? (
              <TitleListItem>
                {t('brand_name')}
              </TitleListItem>
            ) : (
              <Box sx={{ height: '30px' }} />
            )}

            <StructureLeftMenuItem
              icon={HomeIcon}
              description="home"
              path="/home"
              drawerreduced={drawerreduced}
              location={location}
              navigate={navigate}
              t={t}
            />

            {!drawerreduced && (
              <TitleListItem>
                {t('my_wealth')}
              </TitleListItem>
            )}

            <StructureLeftMenuItem
              icon={PaymentIcon}
              description={t('assets')}
              path="/assets/all"
              drawerreduced={drawerreduced}
              location={location}
              navigate={navigate}
              t={t}
            />

            <StructureLeftMenuItem
              icon={PaymentIcon}
              description={t('liabilities')}
              path="/liabilities/all"
              drawerreduced={drawerreduced}
              location={location}
              navigate={navigate}
              t={t}
            />

            <StructureLeftMenuItem
              icon={PaymentIcon}
              description={t('incomes')}
              path="/incomes/all"
              drawerreduced={drawerreduced}
              location={location}
              navigate={navigate}
              t={t}
            />

            <StructureLeftMenuItem
              icon={PaymentIcon}
              description={t('expenses')}
              path="/expenses/all"
              drawerreduced={drawerreduced}
              location={location}
              navigate={navigate}
              t={t}
            />

          </List>

        </Drawer>

        <LoggedInCentralContainer
          $isMobile={isMobile}
          $drawerreduced={drawerreduced}
        >
          {children}
        </LoggedInCentralContainer>

        {isMessagesModalOpen &&
          <ModalReadMessage
            onClose={closeMessagesModal}
            closeModal={handleCloseMessageModal}
            msg_id={currentMessage.msg_id}
            subject={currentMessage.subject}
            content={currentMessage.content}
            created_at={currentMessage.created_at}
          />
        }
      </ThemeProvider>
    </Box>
  );
}

export default StructureLogged;