import { getCookieValue, setCookieValue } from './App';
import config from './config/env.json';
import { TextField, MenuItem } from "@mui/material";
import { t } from './Util_format';
import { createRoot } from 'react-dom/client';

export async function fetchPageData(page_to_fetch) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/user/get_page_data`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ page: page_to_fetch })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export async function markMessageAsRead(msg_id) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/user/messages/mark_as_read`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ msg_id: msg_id })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCardTransactions(card_id, months_to_show, transaction_type) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/baas/stored/cards/transactions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ card_id: card_id, months_to_show: months_to_show, transaction_type: transaction_type, source: 'spanner' })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function get_page_data(page, type) {
  const token = getCookieValue('firebaseToken');
  let url;
  let requestBody;

  switch (page) {
    case 'assets':
      url = `${config.API_DOMAIN_RETAIL}/api/wealth/page/assets/` + type;
      break;
    case 'liabilities':
      url = `${config.API_DOMAIN_RETAIL}/api/wealth/page/liabilities/` + type;
      break;
    default:
      throw new Error('ERROR - PAGE NOT DEFINED in get_page_data'); // Throw an error instead of returning a string
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    throw error; // Rethrow the error after logging it to handle it further up the call stack if needed
  }
}

export async function fetchSaveSettings(base_currency, PL_calculation_period, show_PL_in) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  if (base_currency !== undefined) {
    requestBody.base_currency = base_currency;
  }

  if (PL_calculation_period !== undefined) {
    requestBody.PL_calculation_period = PL_calculation_period;
  }

  if (show_PL_in !== undefined) {
    requestBody.show_PL_in = show_PL_in;
  }

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/user/save_settings`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchRenameAsset(user_asset_type, user_asset_id, user_asset_label) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    user_asset_label: user_asset_label,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/asset/${user_asset_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchRenameLiability(user_liability_type, user_liability_id, user_liability_label) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_liability_id: user_liability_id,
    user_liability_label: user_liability_label,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/liability/${user_liability_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchUpdateTa(user_asset_type, user_asset_id, user_ta_id) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    user_ta_id: user_ta_id,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/asset/${user_asset_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchFindMonitoredProduct(user_asset_type, filter) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    filter: filter,
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/find/` + user_asset_type, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchUpdateAssetBalance(user_asset_type, user_asset_id, user_asset_balance) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    user_asset_balance: user_asset_balance,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/asset/${user_asset_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchUpdateLiabilityBalance(user_liability_type, user_liability_id, user_liability_balance) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_liability_id: user_liability_id,
    user_liability_balance: user_liability_balance,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/liability/${user_liability_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}



export async function fetchUpdateAssetEstimatedValue(user_asset_type, user_asset_id, user_asset_estimated_value) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    user_asset_estimated_value: user_asset_estimated_value,
    transaction_date: new Date().toISOString().split('T')[0]
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/edit/asset/${user_asset_type}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchTrendChartData(user_asset_type, period, currency) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/performance/past/trend_chart_assets_value`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_asset_type: user_asset_type,
        period: period,
        currency: currency
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}




export async function fetchCreateAssetsBankAccount(user_asset_label, user_asset_balance, user_asset_currency, user_institution_id, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  if (user_asset_label !== undefined) {
    requestBody.user_asset_label = user_asset_label;
  }
  if (user_asset_balance !== undefined) {
    requestBody.user_asset_balance = user_asset_balance;
  }
  if (user_asset_currency !== undefined) {
    requestBody.user_asset_currency = user_asset_currency;
  }
  if (user_institution_id !== undefined) {
    requestBody.user_institution_id = user_institution_id;
  }
  if (creation_date !== undefined) {
    requestBody.creation_date = creation_date;
  }

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/assets/bankaccounts`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}


export async function fetchCreateAssetsCredit(user_asset_label, user_asset_balance, user_asset_currency, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  if (user_asset_label !== undefined) {
    requestBody.user_asset_label = user_asset_label;
  }
  if (user_asset_balance !== undefined) {
    requestBody.user_asset_balance = user_asset_balance;
  }
  if (user_asset_currency !== undefined) {
    requestBody.user_asset_currency = user_asset_currency;
  }
  if (creation_date !== undefined) {
    requestBody.creation_date = creation_date;
  }

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/assets/credits`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchBuyAssetsSharesEtfs(user_asset_type, product_code, user_ta_id, unit_price, quantity, transaction_date, purchase_currency) {
  const token = getCookieValue('firebaseToken');

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/trade/buy/` + user_asset_type, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        product_code: product_code,
        user_ta_id: user_ta_id,
        unit_price: unit_price,
        quantity: quantity,
        transaction_date: transaction_date,
        purchase_currency: purchase_currency
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}



export async function fetchCreateAssetsCommodities(product_code, user_ta_id, unit_price, quantity, transaction_date, purchase_currency) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/trade/buy/commodities`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        product_code: product_code,
        user_ta_id: user_ta_id,
        unit_price: unit_price,
        quantity: quantity,
        transaction_date: transaction_date,
        purchase_currency: purchase_currency
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}
export async function fetchCreateAssetsVehicle(user_asset_label, user_asset_purchase_price, user_asset_estimated_value, user_asset_currency, user_asset_country, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/assets/vehicles`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_asset_label: user_asset_label,
        user_asset_purchase_price: user_asset_purchase_price,
        user_asset_estimated_value: user_asset_estimated_value,
        user_asset_currency: user_asset_currency,
        user_asset_purchase_currency: user_asset_currency,
        user_asset_product_currency: user_asset_currency,
        user_asset_country: user_asset_country,
        creation_date: creation_date
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCreateAssetsRealEstate(user_asset_label, user_asset_purchase_price, user_asset_estimated_value, user_asset_currency, user_asset_country, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/assets/realestates`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_asset_label: user_asset_label,
        user_asset_purchase_price: user_asset_purchase_price,
        user_asset_estimated_value: user_asset_estimated_value,
        user_asset_currency: user_asset_currency,
        user_asset_purchase_currency: user_asset_currency,
        user_asset_product_currency: user_asset_currency,
        user_asset_country: user_asset_country,
        creation_date: creation_date
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCreateLiabilitiesCreditCard(user_liability_label, user_liability_balance, user_liability_currency, user_institution_id, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  if (user_liability_label !== undefined) {
    requestBody.user_liability_label = user_liability_label;
  }
  if (user_liability_balance !== undefined) {
    requestBody.user_liability_balance = user_liability_balance;
  }
  if (user_liability_currency !== undefined) {
    requestBody.user_liability_currency = user_liability_currency;
  }
  if (user_institution_id !== undefined) {
    requestBody.user_institution_id = user_institution_id;
  }
  if (creation_date !== undefined) {
    requestBody.creation_date = creation_date;
  }

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/liabilities/creditcard`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCreateLiabilitiesOther(user_liability_label, user_liability_balance, user_liability_currency, user_liability_country, creation_date) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {};

  if (user_liability_label !== undefined) {
    requestBody.user_liability_label = user_liability_label;
  }
  if (user_liability_balance !== undefined) {
    requestBody.user_liability_balance = user_liability_balance;
  }
  if (user_liability_currency !== undefined) {
    requestBody.user_liability_currency = user_liability_currency;
  }
  if (user_liability_country !== undefined) {
    requestBody.user_liability_country = user_liability_country;
  }
  if (creation_date !== undefined) {
    requestBody.creation_date = creation_date;
  }

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/liabilities/others`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCreateTa(user_ta_label, user_ta_type, user_ta_location, user_institution_id) {
  const token = getCookieValue('firebaseToken');
  let requestBody = {
    user_ta_label: user_ta_label,
    user_ta_type: user_ta_type,
    user_ta_location: user_ta_location,
    user_institution_id: user_institution_id,
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/ta`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCreateInstitution(user_institution_label, user_institution_country) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_institution_label: user_institution_label,
    user_institution_country: user_institution_country
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/create/institution`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}


export async function fetchCloseAssetBankAccount(user_asset_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/asset/bankaccount`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCloseAssetCredit(user_asset_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/asset/credit`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCloseAssetVehicle(user_asset_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/asset/vehicle`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCloseAssetRealestate(user_asset_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_asset_id: user_asset_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/asset/realestate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCloseLiabilityCreditCard(user_liability_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_liability_id: user_liability_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/liability/creditcard`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchCloseLiabilityOther(user_liability_id, transaction_date) {
  const token = getCookieValue('firebaseToken');

  let requestBody = {
    user_liability_id: user_liability_id,
    transaction_date: transaction_date
  };

  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/close/liability/other`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchTradeSellSharesEtfs(user_asset_type, user_asset_id, quantity, unit_price, transaction_date) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/trade/sell/` + user_asset_type, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_asset_id: user_asset_id,
        quantity: quantity,
        unit_price: unit_price,
        transaction_date: transaction_date
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}

export async function fetchTradeSellCommodities(user_asset_id, quantity, unit_price, transaction_date) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/trade/sell/commodities`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_asset_id: user_asset_id,
        quantity: quantity,
        unit_price: unit_price,
        transaction_date: transaction_date
      })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
  }
}


export async function fetchShareTransactions(user_asset_type, user_asset_id) {
  const token = getCookieValue('firebaseToken');
  try {
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/wealth/transactions/shares/list`, {
      method: 'POST', // Assuming POST method as per your example
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ user_asset_type, user_asset_id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error calling the API:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
}

export async function changeUserLanguage(language) {
  const token = getCookieValue("firebaseToken");
  if (!token) return;
  try {
    const API_URL = await get_api_url();
    const business_id = getCookieValue("business_id") || "";
    const data = { value: language };
    if (business_id) {
      data.business_id = business_id;
    }
    await fetch(
      `${API_URL}/api/user/settings/update/general/user_language`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export function isBusinessAccount() {
  const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');
  return RETAIL_OR_BUSINESS === 'BUSINESS';
}


export async function get_api_url() {
  const RETAIL_OR_BUSINESS = getCookieValue('RETAIL_OR_BUSINESS');

  if (config.ENABLE_BUSINESS !== "true" && RETAIL_OR_BUSINESS) {
    return config.API_DOMAIN_RETAIL;
  }

  if (RETAIL_OR_BUSINESS === 'BUSINESS') {
    renderUserTenancies();
    return config.API_DOMAIN_BUSINESS;
  }

  if (RETAIL_OR_BUSINESS === 'RETAIL') {
    renderUserTenancies();
    return config.API_DOMAIN_RETAIL;
  }

  const container = document.getElementById('account-in-use');

  // Clear the container for React rendering
  if (container) {
    container.innerHTML = '';
  }

  let data = null;

  // Utility function to get a cookie
  const getAccountsFromCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
  };

  let retail_tenancies = JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
  let business_tenancies = JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');

  try {
    const token = getCookieValue("firebaseToken");
    const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/auth/profile`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error({ status: 'error', error_code: response.status, fdt_error_code: errorResponse.fdt_error_code });
      return;
    }

    data = await response.json();

    var isPassedKyc = false;
    if (data && data.retail) {
      isPassedKyc = data.retail.kyc_status_id === 100;
      retail_tenancies = [
        {
          type: 'retail',
          value: data.retail.customer_id,
          label: isPassedKyc && data.retail.access_name ? data.retail.access_name : t('open_retail_account'),
          inUse: isPassedKyc ? true : false,
          statusId: data.retail.kyc_status_id,
        },
      ];
      setCookieValue('retail_tenancies', JSON.stringify(retail_tenancies), 7);
      if (isPassedKyc) {
        setCookieValue('RETAIL_OR_BUSINESS', 'RETAIL');
      }
    }

    if (config.ENABLE_BUSINESS !== "true") {
      return config.API_DOMAIN_RETAIL;
    }

    var isPassedKyb = false;
    if (data && data.business && data.business.length > 0) {
      business_tenancies = data.business.map(business => ({
        type: 'business',
        business_id: business.business_id,
        value: business.business_id,
        label: business.access_name,
        inUse: false,
        statusId: business.kyb_status_id,
      }));
      if (!isPassedKyc) {
        const activatedBusiness = business_tenancies.find((e) => e.statusId === 100);
        isPassedKyb = activatedBusiness !== undefined;
        if (isPassedKyb) {
          activatedBusiness.inUse = true;
          setCookieValue('business_id', activatedBusiness.business_id);
          setCookieValue('RETAIL_OR_BUSINESS', 'BUSINESS');
        }
      }
      setCookieValue('business_tenancies', JSON.stringify(business_tenancies), 7);
    }

    renderUserTenancies();
    if (isPassedKyc) return config.API_DOMAIN_RETAIL;
    if (isPassedKyb) return config.API_DOMAIN_BUSINESS;
    // DEFAULT TO RETAIL
    setCookieValue('RETAIL_OR_BUSINESS', 'RETAIL');
    return config.API_DOMAIN_RETAIL;
  } catch (error) {
    console.error('Error fetching data:', error);
    return;
  }
}


export function isUserPassedKyc() {
  return getRetailAccounts().find((e) => e.statusId === 100) !== undefined;
}

export async function fetchVerifyLoginOTP(otpCode) {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const business_id = getCookieValue("business_id") || "";
    const data = {
      otp_code: otpCode,
    };
    if (business_id) {
      data.business_id = business_id;
    }
    const response = await fetch(`${API_URL}/api/auth/login-mfa/verify-otp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (!response.ok) {
      return {
        status: "error",
        message: result.message,
        details: result.details,
      };
    }

    // Return success
    return { status: "success", data: result };
  } catch (error) {
    console.error("Error calling the API:", error);
    return {
      status: "error",
      errorDetails: { general: ["Network error occurred."] },
    };
  }
}


export async function fetchSendLoginOTP() {
  const token = getCookieValue("firebaseToken");
  try {
    const API_URL = await get_api_url();
    const business_id = getCookieValue("business_id") || "";
    const data = {
      device_type: "desktop",
      delivery_method: 'auto'
    };
    if (business_id) {
      data.business_id = business_id;
    }
    const response = await fetch(`${API_URL}/api/auth/login-mfa/send-verification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error calling the API:", error);
  }
}

export function getBusinessAccounts() {
  return JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');
}

export async function sendUserEmailVerification(uid) {
  const response = await fetch(
    `${config.ACCOUNT_BASE_URL}/firebase_send_registration_email?userId=${uid}&language=${getCookieValue("language") === "en" ? "en" : "it"}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  const result = await response.json();
  if (!result.status || result.status !== "success") {
    throw new Error("Unexpected error occurred");
  }
  return true;
}


function renderUserTenancies() {
  // Utility function to get a cookie
  const getAccountsFromCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
  };

  let personalAccounts = JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
  let businessAccounts = JSON.parse(getAccountsFromCookie('business_tenancies') || '[]');

  const container = document.getElementById('account-in-use');
  if (!container) return;

  const accounts = [...personalAccounts, ...businessAccounts, {
    type: 'business',
    value: 'new-application',
    label: t('open_your_business_account')
  }];

  // Create root only if it doesn't exist
  if (!container._reactRootContainer) {
    const root = createRoot(container);
    container._reactRootContainer = root;
  }

  container._reactRootContainer.render(
    <TextField
      select
      name="accountInUse"
      fullWidth
      margin="dense"
      style={{ minWidth: '250px', marginRight: '10px', color: "#EBF2FA", fontFamily: 'Poppins' }}
      value={accounts.find(account => account.inUse)?.value || ''}
      onChange={(event) => handleTenancyChange(event, accounts, personalAccounts, businessAccounts)}
    >
      {accounts.map(account => (
        <MenuItem key={account.value} value={account.value}>
          {account.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

function handleTenancyChange(event, accounts, personalAccounts, businessAccounts) {
  const selectedTenancy = accounts.find(account => account.value === event.target.value);
  if (selectedTenancy.statusId !== 100) {
    if (selectedTenancy.type === 'business') {
      if (selectedTenancy.business_id) {
        window.location.href = `/kyb/${selectedTenancy.business_id}`;
      } else {
        window.location.href = '/new_account/business';
      }
    } else {
      window.location.href = '/kyc';
    }
    return;
  }

  accounts.forEach(account => account.inUse = false);
  if (selectedTenancy) selectedTenancy.inUse = true;
  setCookieValue('retail_tenancies', JSON.stringify(personalAccounts), 7);
  setCookieValue('business_tenancies', JSON.stringify(businessAccounts), 7);
  setCookieValue('RETAIL_OR_BUSINESS', selectedTenancy.value === personalAccounts[0].value ? 'RETAIL' : 'BUSINESS');
  if (selectedTenancy.type === 'business') {
    setCookieValue('business_id', selectedTenancy.business_id);
  } else {
    setCookieValue('business_id', '');
  }
  renderUserTenancies(); // Re-render to update inUse status
  window.location.reload();
}

export function getRetailAccounts() {
  return JSON.parse(getAccountsFromCookie('retail_tenancies') || '[]');
}

  // Utility function to get a cookie
  const getAccountsFromCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
  };

