import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import firebaseConfig from './config/firebase_config.json';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import config from './config/env.json';
import './css/GoogleMaps.css';
import "vanilla-cookieconsent/dist/cookieconsent.css";

// Unlogged-ONLY Pages
import PageLogin from './PageLogin';
import PageLoginOtp from './PageLoginOtp';
import PageWaitingPush from './PageWaitingPush';
import PageRegister from './PageRegister';
import PageKyc from './PageKyc';
import PageResetPassword from './PageResetPassword';
import PageAuthAction from './PageAuthAction';
import PageKillSession from './PageKillSession';

// Logged-ONLY Pages
import StructureLogged from './StructureLogged';
import PageIdentification from './PageIdentification';
import PageOpenAccount from './PageOpenAccount';
import PageHome from './PageHome';
import PageResendRegistrationEmail from './PageResendRegistrationEmail';
import PageTransactions from './PageTransactions';
import PageAssets from './PageAssets';
import PageLiabilities from './PageLiabilities';

// Logged and Unlogged Pages
import PageMaintenanceMode from './PageMaintenanceMode';
import * as CookieConsent from "vanilla-cookieconsent";
import { getCookieConsentConfig } from './utils/CookieConsentConfig';
import ModalConfirmCookieDecline from './ModalConfirmCookieDecline';
import { Backdrop } from '@mui/material';
import { changeUserLanguage } from './Util_API_calls';
import { Helmet } from 'react-helmet';
import { t } from './Util_format';
import { getAuth } from '@firebase/auth';
import { getFirestore } from 'firebase/firestore';

const RedirectIfNoCookie = () => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('YourCookieName='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
        return <Navigate to="/login" />;
    } else {
        return null;
    }
}

const SessionManager = () => {
    const navigate = useNavigate();

    // This function will set a new cookie with the updated expiration time
    const resetSessionTimer = () => {
        // Set the expiration time to 5 minutes from the current time
        const newExpirationTime = new Date(new Date().getTime() + config.INACTIVITY_LOGOUT_TIME_IN_MINUTES * 60000).toUTCString();
        setCookieValue('inactivity_session_expiration_time', newExpirationTime);
        console.log('Session reset at:', newExpirationTime);
    };

    useEffect(() => {
        const isKybPage = window.location.pathname.startsWith('/kyb');
        const isKycPage = window.location.pathname.startsWith('/kyc');
        // This function is called when user interaction is detected
        const handleUserAction = () => {
            resetSessionTimer(); // Reset the session timer upon user action
        };

        // Set up event listeners for user interaction
        window.addEventListener('click', handleUserAction);
        window.addEventListener('keydown', handleUserAction);

        // This interval will check for session expiration
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();

            // Get the stored cookie values for firebase and inactivity expiration
            const firebaseExpirationTime = getCookieValue('firebase_session_expiration_time');
            const inactivityExpirationTime = getCookieValue('inactivity_session_expiration_time');

            // Calculate time left before expiration
            const firebaseTimeLeft = (new Date(firebaseExpirationTime).getTime() - currentTime) / 1000;
            const inactivityTimeLeft = (new Date(inactivityExpirationTime).getTime() - currentTime) / 1000;

            // Check if either session has expired
            if (firebaseTimeLeft <= 0 || (!isKybPage && !isKycPage && inactivityTimeLeft <= 0)) {
                clearInterval(interval); // Stop the interval
                deleteCookie();          // Clear all cookies
                navigate('/login?action=clean-cookie&reason=session_expired');      // Redirect to the login page
            } else {
                // Optionally log the time left for each session (for debugging purposes)
                if (!isNaN(firebaseTimeLeft)) {
                    //        console.log(`Firebase time left: ${Math.floor(firebaseTimeLeft)} seconds`);
                }
                if (!isNaN(inactivityTimeLeft)) {
                    //        console.log(`Inactivity time left: ${Math.floor(inactivityTimeLeft)} seconds`);
                }
            }
        }, 1000);

        // This will be called when the component unmounts
        return () => {
            if (isKybPage) {
                resetSessionTimer();
            }
            clearInterval(interval); // Clear the interval
            // Remove event listeners to prevent memory leaks
            window.removeEventListener('click', handleUserAction);
            window.removeEventListener('keydown', handleUserAction);
        };
    }, [navigate]); // Only re-run the effect if navigate changes

    return null; // This component doesn't render anything
};

function LoggedInWrapper({ dashboard_structure, PageContent }) {
    return (
        <Box>
            {React.cloneElement(dashboard_structure, { children: PageContent })}
        </Box>
    );
}

export const changeLanguage = (language) => {
    localStorage.setItem("change_user_language", "true");
    document.cookie = `language=${language}; path=/; samesite=strict`;
    window.location.reload();
}

export const setCookieValue = (name, value) => {
    document.cookie = `${name}=${value}; path=/; samesite=strict`;
};

export const getCookieValue = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([.$?*|{}()[\]/+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
    }
};

export const deleteSpecificCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

const App = () => {

    const [APIPageData, setAPIPageData] = useState(null);
    const [isOpenCookieDeclineConfirm, setOpenCookieDeclineConfirm] = useState(false);
    const [isDisableCookie, setDisableCookie] = useState(false);

    const onCloseCookieDeclineConfirm = (isAccept) => {
        setOpenCookieDeclineConfirm(false);
        if (isAccept) {
            CookieConsent.acceptCategory([]);
        }
    }

    const handleUpdateNotificationBell = (PageData) => {
        if (document.getElementById('page_dashboard')) {
            if (PageData && typeof PageData === 'object') {
                setAPIPageData(PageData);
                return PageData;
            } else {
                console.log("PageData is not an object.");
            }
        } else {
            console.log("Element with ID 'page_dashboard' does not exist.");
        }
        return 0;
    };

    useEffect(() => {
        if (localStorage.getItem("change_user_language") === "true") {
            changeUserLanguage(getCookieValue("language") === "en" ? "en" : "it");
        }
        setDisableCookie(!CookieConsent.acceptedCategory("technical"));
        CookieConsent.run({
            ...getCookieConsentConfig(
                getCookieValue("language") === "en" ? "en" : "it"
            ),
            onConsent: ({ cookie }) => {
                if (!cookie.categories.find((e) => e === "technical")) {
                    deleteCookie();
                    setDisableCookie(true);
                } else {
                    setDisableCookie(false);
                }
            },
            onModalReady: ({ modalName, modal }) => {
                const linkGroup = modal.querySelector('.cm__link-group');
                if (linkGroup) {
                    linkGroup.style.display = 'block'; // or 'initial'
                    linkGroup.style.width = '100%';
                    linkGroup.style.fontSize = '0.8em';
                }
                if (modalName === "consentModal") {
                    modal
                        .querySelector('[type="button"][data="accept"]')
                        .addEventListener("click", () => {
                            CookieConsent.acceptCategory("all");
                            CookieConsent.hide();
                            setDisableCookie(false);
                        });
                    modal
                        .querySelector('[type="button"][data="decline"]')
                        .addEventListener("click", () => {
                            setOpenCookieDeclineConfirm(true);
                        });
                    modal
                        .querySelector(
                            '[type="button"][data="setting"]'
                        )
                        .addEventListener("click", () => {
                            CookieConsent.showPreferences();
                        });
                }
            },
        });
    }, []);

    return (
        <Router>
            <SessionManager />
            <Routes>
                <Route path="/" element={<RedirectIfNoCookie />} />
                <Route path="/login" element={<PageLogin />} />
                <Route path="/Loginotp" element={<PageLoginOtp />} />
                <Route path="/register" element={<PageRegister />} />
                <Route path="/AuthAction" element={<PageAuthAction />} />
                <Route
                    path="/home"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageHome onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/create"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType/:operation"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets />}
                        />
                    }
                />
                <Route
                    path="/assets/edit/"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType/:operation/:assetID"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageAssets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/create"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType/:operation"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities />}
                        />
                    }
                />
                <Route
                    path="/liabilities/edit/"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType/:operation/:liabilityID"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageLiabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/transactions"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged APIPageData={APIPageData} />}
                            PageContent={<PageTransactions onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/user-guide"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<StructureLogged onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
            </Routes>
        </Router>
    );
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default App;