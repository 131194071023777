import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    DialogContent,
    Paper,
    Typography
} from '@mui/material';
import { t } from './Util_format';
import { theme } from '@style/styled.js';

function ModalTaList({ onClose, isOpen, assetType, trading_accounts, handleRefreshAssets, handleOpenTaCreateModal }) {

    const handleClose = () => {
        onClose();
    };

    const handleAccountClick = (account) => {
        alert(`Selected Trading Account: ${account.user_ta_label} - Not yet developed in this alpha version.`);
    };

    // Styling for the headers and rows
    const headerStyles = {
        background: '#f0f0f0',
        color: '#000',
        fontWeight: 'bold'
    };

    const rowStyles = {
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{t('list_ta_title_' + assetType)}</DialogTitle>
                <DialogContent>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenTaCreateModal} // Add the onClick event handler here
                        style={{ marginRight: '8px', textTransform: 'capitalize' }}
                    >
                        {t('create_ta_' + assetType)}
                    </Button>
                </DialogContent>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={headerStyles}>
                                    <TableCell align="center">{t('brokerage_account_label')}</TableCell>
                                    <TableCell align="center">{t('brokerage_account_status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trading_accounts.map((account, index) => (
                                    <TableRow key={index} hover onClick={() => handleAccountClick(account)} style={rowStyles}>
                                        <TableCell align="center">{account.user_ta_label}</TableCell>
                                        <TableCell align="center">{t(account.user_ta_current_status)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography sx={{ paddingTop: '20px' }}>
                        {t('click_ta_to_view_or_edit')}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export { ModalTaList };
