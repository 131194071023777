import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Link, CircularProgress, ThemeProvider, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCookieValue, setCookieValue } from './App';

import { t } from './Util_format';
import { fetchSendLoginOTP, fetchVerifyLoginOTP } from './Util_API_calls';
import { theme, ErrorContainer, LoginCentralPic, UnauthenticatedLeftContainer, UnauthenticatedRightContainer, LoginLogoMobile, UnauthenticatedMainContainer, OtpContainer, StyledOtpInput } from '@style/styled.js';

function Loginotp() {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const inputsRef = useRef([]);
  const submitButtonRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      handleSubmit(); // Call the login function when Enter key is pressed
    }
  };

  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;
    const loginOtpCompleted = getCookieValue('Loginotp_completed');
    const KycCompleted = getCookieValue('kyc_completed');

    if (!cookieValue) {
      navigate('/login');
    } else {
      if (KycCompleted !== 'true') {
        navigate('/new_account');
        return;
      }
      if (loginOtpCompleted === 'true') {
        navigate("/home");
      }
    }

    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }

  }, [navigate]);

  useEffect(() => {
    if (timer > 0) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [timer]);

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value) && value !== "") return;

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value) {
      if (index === 5) {
        submitButtonRef.current.focus();
      } else if (index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    setError(null); // Resetting any previous errors
    const otpCode = otp.join("");

    if (!/^\d{6}$/.test(otpCode)) {
      setError("Per favore inserisci un codice OTP di 6 cifre.");
      setIsProcessing(false);
      return;
    }

    try {
      const apiResult = await fetchVerifyLoginOTP(otpCode);
      if (apiResult.details === 'System under maintenance. Please try again later.') {
        navigate("/maintenance");
      }

      if (apiResult.status !== 'success') {
        // Check if there are specific error details
        if (apiResult.message) {
          switch (apiResult.message) {
            case 'The otp code field is required':
              setError("Il codice OTP è richiesto.");
              break;
            case 'OTP has expired':
              setError("Il codice OTP è scaduto. Per favore richiedi un nuovo codice.");
              break;
            case 'Invalid OTP':
              setError("Il codice OTP inserito non è valido.");
              break;
            case 'OTP Attempts Exceeded':
              setError("Tentativi OTP esauriti. Per favore fai un nuovo login.");
              navigate('/login?action=clean-cookie&reason=too_many_attempts');
              break;
            case 'Email was not verified':
              navigate('/login?action=clean-cookie&reason=email_not_verified');
              break;
            default:
              // If no specific error message, display the general error message
              setError(apiResult.message || "Si è verificato un errore.");
              break;
          }
        } else {
          // If no specific error details, use the general error message
          setError(apiResult.message || "Si è verificato un errore.");
        }
        setIsProcessing(false);
        return;
      }
      setCookieValue('Loginotp_completed', 'true');
      navigate("/home");
    } catch (error) {
      setError("Si è verificato un errore di rete.");
      setIsProcessing(false);
    }
  };

  const handleResendOtp = async () => {
    setIsProcessing(true);
    setError(null); // Resetting any previous errors
    setOtp(Array(6).fill(""));
    try {
      await fetchSendLoginOTP();
      setTimer(30);
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box id="PageLoginOtp">
        <UnauthenticatedMainContainer>
          <UnauthenticatedLeftContainer>
            <LoginCentralPic></LoginCentralPic>
          </UnauthenticatedLeftContainer>

          <UnauthenticatedRightContainer>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2
              }}
            >
              <LoginLogoMobile />
              {t("sms_verification_title")}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t("we_sent_you_code")}
            </Typography>

            <OtpContainer>
              {otp.map((digit, index) => (
                <StyledOtpInput
                  key={index}
                  variant="outlined"
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center', width: '2ch' }
                  }}
                  inputRef={ref => inputsRef.current[index] = ref}
                  value={digit}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </OtpContainer>

            {error && (
              <ErrorContainer isModal={false}>{error}</ErrorContainer>
            )}

            <Button
              ref={submitButtonRef}
              variant="contained"
              disabled={isProcessing}
              onClick={handleSubmit}
              sx={{
                textTransform: "none",
                backgroundColor: isProcessing
                  ? "#AAAAAA !important"
                  : undefined,
                color: isProcessing ? "#FFFFFF !important" : undefined,
                minWidth: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("confirm")
              )}
            </Button>
            {timer > 0 ? (
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',  // Add this to center the content
                  width: '100%',            // Add this to make it take full width
                  gap: 0.5,
                }}
              >
                {t("resend_sms_in")}
                <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
                  <CircularProgress
                    size={26}
                    variant="determinate"
                    value={(timer / 30) * 100}
                  />
                  <Typography
                    variant="caption"
                    sx={{
                      margin: '0px',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {timer}
                  </Typography>
                </Box>
              </Typography>
            ) : (
              <Link
                id="resendSMSLink"
                href="#"
                variant="body2"
                onClick={handleResendOtp}
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  t("resend_sms")
                )}
              </Link>
            )}

            <Link href="/login?action=clean-cookie">
              <Typography variant="body2" >
                {t('back_to_login')}
              </Typography>
            </Link>
          </UnauthenticatedRightContainer>
        </UnauthenticatedMainContainer>
      </Box>
    </ThemeProvider>
  );
}

export default Loginotp;